import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The Dolomites in Italy are famous for their unique mountains. High vertical
rock walls merge into mellow hills covered in lush meadows.`}</p>
    <br />
    <p>{`From Canazei
several lifts bring you up to the bike park and natural singletracks. The
trail network is not limited to Canazei and with one lift ticket you can
access almost 100 lifts in the whole Dolomites. The most spectacular lift
supported round tour is probably the Sella Ronda.`}</p>
    <br />
    <p>{`Four different mountain passes
and valleys - that are also well known for road biking - can be crossed in a
long day with around 60 km and 4000vm on singletracks. Canazei is the venue
for the Italian Superenduro tour and will host the Enduro World Series in 2019.
Best time of the year is from June to September.`}</p>
    <TrailguidePlugin content="lat=46.475&lng=11.764&coverage=10" mdxType="TrailguidePlugin" />
    <h4>{`Double U`}</h4>
    <p>{`Bike park style track with lots of berms and fast corners in front
of a great scenery.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1908"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/canazei_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Electric line`}</h4>
    <p>{`Steep terrain and lots of roots await you.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1907"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/canazei_2.jpg" mdxType="Image" />
    <br />
    <h4>{`The ridge`}</h4>
    <p>{`From the Col Rodella a fantastic trail follows a wide ridge. Marmolada
and Sella group in the backround.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1906"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/canazei_4.jpg" mdxType="Image" />
    <br />
    <h4>{`The ridge`}</h4>
    <p>{`Just impressive.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1906"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/canazei_3.jpg" mdxType="Image" />
    <br />
    <h4>{`Infinity`}</h4>
    <p>{`Nice natural singletrack with lots of roots.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1904"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/canazei_6.jpg" mdxType="Image" />
    <br />
    <h4>{`4x`}</h4>
    <p>{`Fast track with nice little jumps.
Click `}<a parentName="p" {...{
        "href": "https://trailguide.net/1905"
      }}>{`here`}</a>{` to find out more.`}</p>
    <Image src="destinations/canazei_5.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      